<template>
  <div class="smsSettings">
    <div class="ml-20 mt-20 mb-20">根据录音地址查询主被叫</div>
    <div class="flex-nowrap-space-around-other">
      <div class="input-searchFrom-width-600">
        <el-form ref="customerForm" size="small" :model="customerModel" label-width="120px" :rules="rules">
          <el-form-item label="关键字：" prop="key">
            <el-input v-model="customerModel.key" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button :loading="buttonLoading" class="yes-green-bg" @click="queryForm">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="input-searchFrom-width-600 show-boxs">
        <div v-if="dataList.length == 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div v-else>
          <div v-for="(item,i) in dataList" :key="i" class="flex-nowrap-space-around item-box-other">
            <div>{{ item.key }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhan"></div>
 <div class="ml-20 mt-20 mb-20">根据主被叫查询上游原始绑定记录

 </div>
  <div class="flex-nowrap-space-around-other">
      <div class="input-searchFrom-width-600">
        <el-form ref="phoneFrom" size="small" :model="phoneFrom" label-width="120px" :rules="rules1">
          <el-form-item label="telA:" prop="telA">
            <el-input v-model="phoneFrom.telA"  placeholder="请输入telA"></el-input>
          </el-form-item>
           <el-form-item label="telB:" prop="telB">
            <el-input v-model="phoneFrom.telB"  placeholder="请输入telB"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button :loading="buttonLoading1" class="yes-green-bg" @click="bindQueryList">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
  <div class="input-searchFrom-width-600 show-boxs">
        <div v-if="dataList1.length == 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div v-else>
          <div v-for="(item,i) in dataList1" :key="i" class="flex item-box-other">
            <div>{{ item.time }}</div>
            <div class="min-box">{{ item.message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toolDataQuery ,bindQuery} from '@/api/open/tool'
import { ElNotification } from 'element-plus'
export default {
  components: {},
  data() {
    return {
      customerModel: {
        key: '',
      },
       phoneFrom: {
        telA: '',
        telB:'',
      },
      rules: {
        key: [
          {
            required: true,
            message: '请输入关键字',
            trigger: 'blur',
          },
        ],
         telA: [
          {
            required: true,
            message: '请输入telA',
            trigger: 'blur',
          },
        ],
         telB: [
          {
            required: true,
            message: '请输入telB',
            trigger: 'blur',
          },
        ],
      },


    rules1: {
        
         telA: [
          {
            required: true,
            message: '请输入telA',
            trigger: 'blur',
          },
        ],
         telB: [
          {
            required: true,
            message: '请输入telB',
            trigger: 'blur',
          },
        ],
      },

      dataList: [],
       dataList1: [],
      buttonLoading:false,
            buttonLoading1:false

    }
  },
  methods: {
bindQueryList(){


  this.dataList1 = []
      this.$refs.phoneFrom.validate((res) => {
             if (res) {
              this.buttonLoading1 = true
   bindQuery(this.phoneFrom).then((res)=>{
            let { code, data,message}  = res
            if(code == 200){
              
 let dataList = []
                for( let time in data){
                    dataList.push({
                        time,
                        message:data[time]
                    })
                }
                this.dataList1 = dataList

            }else{
                 ElNotification({
                    title: '警告',
                    message,
                    type: 'warning',
                });
            }
          }).finally(()=>{
            this.buttonLoading1 = false
          })
        }

           })
},

    queryForm() {
    this.dataList = []
      this.$refs.customerForm.validate((res) => {
        if (res) {
          this.buttonLoading = true
          toolDataQuery(this.customerModel).then((res)=>{
            let { code, data,message}  = res
            if(code == 200){
                let dataList = []
                for( let key in data){
                    dataList.push({
                        key,
                        value:data[key]
                    })
                }
                this.dataList = dataList
            }else{
                 ElNotification({
                    title: '警告',
                    message,
                    type: 'warning',
                });
            }
          }).finally(()=>{
            this.buttonLoading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.zhan{
  width: 100%;
  height: 3px;
  background-color: #ebebeb;
  margin-right: 5px;
}
// .smsSettings{
//     // margin-top: 60px;
// }
.show-boxs {
overflow-y: scroll;
height: 50vh;
  border: 1px solid #0000;
}
.item-box-other {
  

 // width: 500px;
  padding: 8px 16px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #ebebeb;
   .min-box{
width: 100%;
   word-break: break-all;

   }
}
</style>